import { render, staticRenderFns } from "./RequireActivation.vue?vue&type=template&id=bca1813e&scoped=true&lang=pug&"
import script from "./RequireActivation.vue?vue&type=script&lang=js&"
export * from "./RequireActivation.vue?vue&type=script&lang=js&"
import style0 from "./RequireActivation.vue?vue&type=style&index=0&id=bca1813e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca1813e",
  null
  
)

export default component.exports