export const COLLECTIONS = {
  TRAINERS: 'TRAINERS',
  CLIENTS: 'CLIENTS',
  PAIRS: 'PAIRS',
  RECORDS: 'RECORDS',
  SETS: 'SETS',
  MENUITEMS: 'MENUITEMS',
  SHARED: 'SHARED',
  ACTIVE: 'ACTIVE'
}
