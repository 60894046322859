import db, { firebase } from '@/components/utils/firebase'
import { COLLECTIONS } from '@/enums'

export const state = () => ({
  records: null
})

export const mutations = {
  replaceRecords (state, records) {
    state.records = records
    state.records.splice()
  },
  replaceOneRecord (state, record) {
    state.records = state.records.map(e => {
      if (e.id === record.id) {
        return record
      } else {
        return e
      }
    })
  },
  addRecord (state, record) {
    state.records.unshift(record)
  },
  deleteRecord (state, recordId) {
    state.records = state.records.filter(e => { return (e.id !== recordId) })
  },
  updateRecord (state, data) {
    state.records = state.records.map(e => {
      if (e.id === data.docId) {
        return data.recordObj
      } else {
        return e
      }
    })
  }
}

export const actions = {
  async createRecord ({ commit }, record) {
    return new Promise(async resolve => {
      var id = await db.collection(COLLECTIONS.RECORDS)
        .add(record)
        .then((d) => {
          return d.id
        })

      resolve(id)
    })
  },
  async saveRecord ({ commit }, data) {
    return new Promise(async resolve => {
      if (data.isNew) {
        data.recordObj.created = new Date()
        await db.collection(COLLECTIONS.RECORDS).doc(data.docId).set(data.recordObj, { merge: true })
      } else {
        await db.collection(COLLECTIONS.RECORDS).doc(data.docId).update(data.recordObj)
      }

      // clientの最終更新日を記録
      db.collection(COLLECTIONS.CLIENTS)
        .doc(data.recordObj.clientId)
        .update({ 'updated': new Date() })

      // PAIRSの管理
      var pairDoc = await db.collection(COLLECTIONS.PAIRS)
        .where('trainerId', '==', data.recordObj.uid)
        .where('clientId', '==', data.recordObj.clientId)
        .get()

      if (!pairDoc.exists) {
        db.collection(COLLECTIONS.PAIRS).add({
          trainerId: data.recordObj.uid,
          clientId: data.recordObj.clientId,
          author: data.recordObj.uid
        })
      }

      resolve(data.recordObj)
    })
  },
  async loadRecords ({ commit }, clientId) {
    return new Promise(async resolve => {
      // var records = await db.collection(COLLECTIONS.RECORDS)
      //   .where('clientId', '==', clientId)
      //   .orderBy('created', 'desc')
      //   .get()
      //   .then(q => {
      //     return q.docs.map(d => {
      //       var record = d.data()
      //       record.id = d.id
      //       return record
      //     })
      //   })
      // commit('replaceRecords', records)

      await db.collection(COLLECTIONS.RECORDS)
        .where('clientId', '==', clientId)
        .orderBy('created', 'desc')
        .onSnapshot((q) => {
          var records = q.docs.map(d => {
            var record = d.data()
            record.id = d.id
            return record
          })
          commit('replaceRecords', records)
          resolve(records)
        })
    })
  },
  getRecordById ({ state }, recordId) {
    return new Promise(async resolve => {
      var record = await db.collection(COLLECTIONS.RECORDS)
        .doc(recordId)
        .get()
        .then(d => {
          var record = d.data()
          record.id = d.id
          return record
        })
      resolve(record)
    })
  },
  deleteRecord ({ commit }, record) {
    db.collection(COLLECTIONS.RECORDS).doc(record.id).delete()

    if (record.body) {
      for (var i = 0; i < record.body.length; i++) {
        for (var setsI = 0; setsI < record.body[i].sets.length; setsI++) {
          var setId = record.body[i].sets[setsI]
          db.collection(COLLECTIONS.SETS).doc(setId).delete()
        }
      }
    }

    commit('deleteRecord', record.id)
  },
  getPreviousRecordOf ({ state }, data) {
    return new Promise(async resolve => {
      var record = await db.collection(COLLECTIONS.RECORDS)
        .where('created', '<', data.created)
        .where('clientId', '==', data.clientId)
        .where('type', '==', data.type)
        .orderBy('created', 'desc')
        .limit(1)
        .get()
        .then(q => {
          var record = (q.docs[0]) ? q.docs[0].data() : null
          if (record) record.id = q.docs[0].id
          return record
        })
      resolve(record)
    })
  },
  getRecentRecords ({ state }, data) {
    return new Promise(async resolve => {
      var record = await db.collection(COLLECTIONS.RECORDS)
        .where('created', '<', data.created)
        .where('clientId', '==', data.clientId)
        .where('type', '==', data.type)
        .orderBy('created', 'desc')
        .limit(10)
        .get()
        .then(q => q.docs.map(d => {
          return { ...d.data(), id: d.id }
        }))
      resolve(record)
    })
  },
  getNextRecordOf ({ state }, data) {
    return new Promise(async resolve => {
      var record = await db.collection(COLLECTIONS.RECORDS)
        .where('created', '>', data.created)
        .where('clientId', '==', data.clientId)
        .where('type', '==', data.type)
        .orderBy('created', 'asc')
        .limit(1)
        .get()
        .then(q => {
          var record = (q.docs[0]) ? q.docs[0].data() : null
          if (record) record.id = q.docs[0].id
          return record
        })
      resolve(record)
    })
  },
  getLatestRecordOf ({ state }, data) {
    return new Promise(async resolve => {
      var record = await db.collection(COLLECTIONS.RECORDS)
        .where('clientId', '==', data.clientId)
        .where('type', '==', data.type)
        .orderBy('created', 'desc')
        .limit(1)
        .get()
        .then(q => {
          var record = (q.docs[0]) ? q.docs[0].data() : null
          if (record) record.id = q.docs[0].id
          return record
        })
      resolve(record)
    })
  },
  async loadMenuItems ({ commit }, uid) {
    return new Promise(async resolve => {
      var menuItems = await db.collection(COLLECTIONS.MENUITEMS)
        // .doc(uid)
        .doc(COLLECTIONS.SHARED)
        .get()
        .then(d => {
          var menuItems = d.data()
          menuItems.id = d.id
          return menuItems
        })
      resolve(menuItems)
    })
  },
  replaceOneRecord ({ commit }, record) {
    // commit("replaceOneRecord", record)
    db.collection(COLLECTIONS.RECORDS).doc(record.id).set(record)
  },
  async createNewSet ({ commit }, setObj) {
    return new Promise(async resolve => {
      var id = await db.collection(COLLECTIONS.SETS)
        .add(setObj)
        .then(doc => {
          return doc.id
        })
      resolve(id)
    })
  },
  async getSet ({ commit }, setId) {
    return new Promise(async resolve => {
      var setObj = await db.collection(COLLECTIONS.SETS)
        .doc(setId)
        .get()
        .then(doc => {
          if (doc.exists) {
            return doc.data()
            // var setObj = doc.data()
            // setObj.id = doc.id
            // return setObj
          } else {
            return null
          }
        })
      resolve(setObj)
    })
  },
  async getSets ({ commit }, setIds) {
    return new Promise(async resolve => {
      var promises = setIds.map(setId => {
        return db.collection(COLLECTIONS.SETS)
          .doc(setId)
          .get()
          .then(d => { return d.data() })
      })
      var sets = await Promise.all(promises)
      resolve(sets)
    })
  },
  async updateSet ({ commit }, data) {
    db.collection(COLLECTIONS.SETS)
      .doc(data.id)
      .set(data.set)
  },
  async deleteSet ({ commit }, setId) {
    db.collection(COLLECTIONS.SETS)
      .doc(setId)
      .delete()
  },
  deleteSets ({ commit }, setIds) {
    for (var i = 0; i < setIds.length; i++) {
      db.collection(COLLECTIONS.SETS).doc(setIds[i]).delete()
    }
  },
  pushNewMenuItem ({ commit }, data) {
    db.collection(COLLECTIONS.MENUITEMS)
      // .doc(data.docId)
      .doc(COLLECTIONS.SHARED)
      .update({
        body: firebase.firestore.FieldValue.arrayUnion(data.trainingMenuObj)
      })
  }
}
