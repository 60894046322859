import firebase from 'firebase/app'

import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

// import { SchedulerlyClient } from '@appsocially/schedulerly-client'

/*
デプロイチェックリスト
1. 起源元の設定（dev、prod両方） https://console.cloud.google.com/functions/list
2. security rule
3. functionsのconfig
4. stripeのconfig
*/

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID
}
// const api = process.env.VUE_APP_API_LOCAL_ORIGIN
const api = process.env.VUE_APP_API_ORIGIN
const domain = process.env.VUE_APP_DOMAIN
const stripeKey = process.env.VUE_APP_STRIPE_KEY

const firebaseApp = firebase.initializeApp(config)
const strage = firebaseApp.storage()
const auth = firebaseApp.auth()
const firestore = firebaseApp.firestore()

const stripe = require('stripe')(stripeKey)

export default firebaseApp.firestore()
export { firestore, firebase, strage, auth, api, stripe, domain, stripeKey }
