<template lang="pug">
  v-app
    v-main
      router-view

    // RequireActivation(v-if="uid")

</template>

<style lang="scss">
@import "./plugins/meltline.css";

html,
body {
  margin: 0;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import RequireActivation from '@/components/shared/RequireActivation.vue'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  name: 'App',
  components: {
    RequireActivation
  },
  data () {
    return {
      //
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        // mixpanel.track('changeRoute', {
        //   from: from.path,
        //   to: to.path,
        //   createdAt: new Date()
        // })
      }
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  }
}
</script>
