import db, { firebase } from '@/components/utils/firebase'
import { COLLECTIONS } from '@/enums'

export const state = () => ({
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  subscriptionEnder: null,
  isAnonymous: null
})

export const mutations = {
  updateUid (state, value) {
    state.uid = value
  },
  updateUserDisplayName (state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating (state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn (state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut (state, value) {
    state.isSigningOut = value
  },
  updateAuthStatus (state, user) {
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
  },
  resetState (state) {
    state.uid = null
    state.userDisplayName = null
    state.userAuthEmail = null
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.isSigningOut = false
    state.isAnonymous = null
  },
  updateSubscriptionEnder (state, value) {
    state.subscriptionEnder = value
  }
}

export const actions = {
  setupAuthStateHandler ({ dispatch, commit, state }) {
    const shouldSetupAuthstate =
      !state.isLoggedIn && state.isAuthenticating && !state.isSigningOut
    if (shouldSetupAuthstate) {
      const ender = firebase.auth().onAuthStateChanged(user => {
        commit('updateAuthStatus', user)
      })

      commit('updateSubscriptionEnder', ender)
    }
  },
  endSubscription ({ state, commit }) {
    if (state.subscriptionEnder) {
      state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async checkTrainerExisting ({ dispatch, commit }, uid) {
    return new Promise(async resolve => {
      var trainerDoc = await db.collection(COLLECTIONS.TRAINERS).doc(uid).get()
      resolve(trainerDoc.exists)
    })
  },
  // createSharedMenuItems({ dispatch, commit }) {
  //   var trainingMenuObj = {
  //     author: "shared",
  //     body: trainingMenuItems
  //   }

  //   db.collection(COLLECTIONS.MENUITEMS)
  //     .doc(COLLECTIONS.SHARED)
  //     .set(trainingMenuObj)
  // },
  async signUp ({ dispatch, commit }, user) {
    return new Promise(async resolve => {
      var trainerObj = {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        lastSignInTime: user.metadata.lastSignInTime,
        created: user.metadata.creationTime
      }

      // var trainingMenuObj = {
      //   author: user.uid,
      //   created: new Date(),
      //   body: trainingMenuItems,
      //   menuItemsId: user.uid
      // }

      // await db.collection(COLLECTIONS.MENUITEMS)
      //   .doc(user.uid)
      //   .set(trainingMenuObj)

      if (!trainerObj.photoURL) trainerObj.photoURL = '/img/default-trainee-icon.png'

      await db.collection(COLLECTIONS.TRAINERS)
        .doc(user.uid)
        .set(trainerObj)
        .then(function () {
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })

      resolve(trainerObj)
    })
  },
  async signOut ({ commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      dispatch('endSubscription')
      await firebase.auth().signOut()
      commit('resetState')
    } catch (err) {
      console.error('error signing out of firebase', err)
    } finally {
      commit('updateIsSigningOut', false)
    }
  },
  async getUserByUid ({ commit }, uid) {
    return new Promise(async resolve => {
      var user = await db.collection(COLLECTIONS.TRAINERS)
        .doc(uid)
        .get()
        .then(d => {
          if (d.exists) {
            var user = d.data()
            user.uid = d.id
            return user
          } else {
            return false
          }
        })

      resolve(user)
    })
  },
  async getAllUsers ({ commit }, uid) {
    return new Promise(async resolve => {
      var users = await db.collection(COLLECTIONS.TRAINERS)
        .get()
        .then((q) => {
          return q.docs.map(d => {
            var trainer = d.data()
            trainer.id = d.id
            return trainer
          })
        })

      resolve(users)
    })
  },
  async setUser ({ commit }, userObj) {
    return new Promise(async resolve => {
      await db.collection(COLLECTIONS.TRAINERS)
        .doc(userObj.uid)
        .set(userObj)
      resolve(userObj)
    })
  },
  async getClientByUid ({ commit }, uid) {
    return new Promise(async resolve => {
      var user = await db.collection(COLLECTIONS.CLIENTS)
        .doc(uid)
        .get()
        .then(d => {
          var user = d.data()
          user.uid = d.id
          return user
        })

      resolve(user)
    })
  },
  async getActiveEmails ({ commit }) {
    return new Promise(async resolve => {
      var data = await db.collection(COLLECTIONS.ACTIVE)
        .doc('active_email')
        .get()
        .then(d => {
          return d.data()
        })

      resolve(data.emails)
    })
  },
  async getSuperAdminEmails ({ commit }) {
    return new Promise(async resolve => {
      var data = await db.collection(COLLECTIONS.ACTIVE)
        .doc('super_admin')
        .get()
        .then(d => {
          return d.data()
        })

      resolve(data.emails)
    })
  },
  async activateTrainer ({ commit }, email) {
    return new Promise(async resolve => {
      await db.collection(COLLECTIONS.ACTIVE)
        .doc('active_email')
        .update({
          'emails': firebase.firestore.FieldValue.arrayUnion(email)
        })

      resolve(true)
    })
  },

  // for client daily task
  async checkAuthedClientExisting ({ dispatch, commit }, uid) {
    return new Promise(async resolve => {
      var clientDoc = await db.collection(COLLECTIONS.CLIENTS)
        .where('authedClientUid', '==', uid)
        .get()
      resolve(!clientDoc.empty)
    })
  },
  async signUpAsClientUser ({ dispatch, commit }, data) {
    return new Promise(async resolve => {
      await db.collection(COLLECTIONS.CLIENTS)
        .doc(data.clientId)
        .update({
          email: data.user.email,
          authedClientUid: data.user.uid
        })

      resolve(true)
    })
  },
  async signUpAsClientUserWithoutInvitation ({ dispatch, commit }, user) {
    return new Promise(async resolve => {
      await db.collection(COLLECTIONS.CLIENTS)
        .doc(user.uid)
        .set({
          author: user.uid,
          authedClientUid: user.uid,
          uid: user.uid,
          created: new Date(),
          email: user.email,
          name: user.displayName,
          photoURL: user.photoURL,
          records: []
        })

      resolve(true)
    })
  }
  // async getClientByAuthedUid({ dispatch, commit }, uid) {
  //   return new Promise(async resolve => {
  //     var clientObj = await db.collection(COLLECTIONS.CLIENTS)
  //       .where("authedClientUid", "==", uid)
  //       .get()
  //       .then((q) => {
  //         var user = q.docs[0].data()
  //         user.id = q.docs[0].id
  //         return user
  //       })
  //     resolve(clientObj)
  //   })
  // }
}
