import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // for trainer user
    {
      path: '/home',
      name: 'home',
      component: () =>
        import('@/views/Home')
    },
    {
      path: '/clients',
      name: 'clients',
      component: () =>
        import('@/views/Clients')
    },
    {
      path: '/trainers',
      name: 'trainers',
      props: true,
      component: () =>
        import('@/views/TrainersList')
    },
    {
      path: '/trainers/:trainerId',
      name: 'trainer',
      props: true,
      component: () =>
        import('@/views/Trainer')
    },
    {
      path: '/inbox',
      name: 'inbox',
      props: true,
      component: () =>
        import('@/views/Inbox')
    },
    {
      path: '/inbox/:groupId',
      name: 'inbox_groupId',
      props: true,
      component: () =>
        import('@/views/Inbox')
    },
    {
      path: '/calendar',
      name: 'calendar',
      props: true,
      component: () =>
        import('@/views/Calendar')
    },
    {
      path: '/calendar/:authedClientUid',
      name: 'calendar_authedClientUid',
      props: true,
      component: () =>
        import('@/views/Calendar')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () =>
        import('@/views/Settings')
    },
    {
      path: '/record/:clientId',
      name: 'record',
      props: true,
      component: () =>
        import('@/views/Record')
    },
    {
      path: '/record/:recordType/:clientId/:recordId',
      name: 'record_recordType',
      props: true,
      component: () =>
        import('@/views/Record')
    },
    {
      path: '/preview/:recordType/:clientId/:recordId',
      name: 'preview',
      props: true,
      component: () =>
        import('@/views/Preview')
    },

    // for client user
    {
      path: '/client_inbox',
      name: 'client_inbox',
      component: () =>
        import('@/views/ClientInbox')
    },
    {
      path: '/client_inbox/:groupId',
      name: 'client_inbox_groupId',
      component: () =>
        import('@/views/ClientInbox')
    },
    {
      path: '/client_schedule',
      name: 'client_schedule',
      component: () =>
        import('@/views/ClientSchedule')
    },
    {
      path: '/client_record',
      name: 'client_record',
      component: () =>
        import('@/views/ClientRecord')
    },
    {
      path: '/client_product',
      name: 'client_product',
      component: () =>
        import('@/views/ClientProduct')
    },
    {
      path: '/client_record/:recordType/:clientId/:recordId',
      name: 'client_record_recordType',
      component: () =>
        import('@/views/ClientRecord')
    },
    {
      path: '/client_account',
      name: 'client_account',
      component: () =>
        import('@/views/ClientAccount')
    },

    // common
    {
      path: '/',
      redirect: '/sign-in'
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () =>
        import('@/views/SignIn')
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () =>
        import('@/views/SignIn')
    },
    {
      path: '/client-sign-in',
      name: 'client-sign-in',
      component: () =>
        import('@/views/SignIn')
    },
    {
      path: '/client-sign-up/:clientId',
      name: 'client-sign-up',
      component: () =>
        import('@/views/SignIn')
    }
    // {
    //   path: '/sign-in/:redirect',
    //   name: 'sign-in',
    //   props: true,
    //   component: () =>
    //     import(/* webpackChunkName: "route-SignIn" */ '@/views/SignIn')
    // }
  ]
})
