import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from './auth'
import * as client from './client'
import * as record from './record'
import * as habits from './habits'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    client: {
      namespaced: true,
      ...client
    },
    record: {
      namespaced: true,
      ...record
    },
    habits: {
      namespaced: true,
      ...habits
    }
  }
})
