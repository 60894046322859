import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#232323'
      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})

// import 'vuetify/src/styles/main.sass'
// import 'vuetify/src/stylus/app.styl'

// Vue.use(Vuetify, {
//   iconfont: 'md'
// })
