import db, { firebase } from '@/components/utils/firebase'
import { getHabits } from '@/database/habits.js'
import { COLLECTIONS } from '@/enums'

export const state = () => ({
  dailyHabits: [],
  weeklyHabits: [],
  monthlyHabits: []
})

export const mutations = {
  replaceDailyHabits(state, habits) {
    state.dailyHabits = habits
  },
  replaceWeeklyHabits(state, habits) {
    state.weeklyHabits = habits
  },
  replaceMonthlyHabits(state, habits) {
    state.monthlyHabits = habits
  },
  addDailyHabit(state, habitName) {
    state.dailyHabits.push(habitName)
  },
  addWeeklyHabit(state, habitName) {
    state.weeklyHabits.push(habitName)
  },
  addMonthlyHabit(state, habitName) {
    state.monthlyHabits.push(habitName)
  }
}

export const actions = {
  async loadHabits({ commit }, data) {
    return new Promise(async resolve => {
      var dailyHabits = await getHabits(data.uid, data.date, "DAILY")
      var weeklyHabits = await getHabits(data.uid, data.date, "WEEKLY")
      var monthlyHabits = await getHabits(data.uid, data.date, "MONTHLY")

      commit('replaceDailyHabits', dailyHabits)
      commit('replaceWeeklyHabits', weeklyHabits)
      commit('replaceMonthlyHabits', monthlyHabits)

      resolve(true)
    })
  },
  pushHabit({ commit }, data) {
    switch (data.habitType) {
      case "DAILY":
        commit('addDailyHabit', data.habitName)
        break
      case "WEEKLY":
        commit('addWeeklyHabit', data.habitName)
        break
      case "MONTHLY":
        commit('addMonthlyHabit', data.habitName)
        break
    }
  }
}
