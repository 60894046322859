<template lang="pug">
transition(name="fade")
  div.require-activation.f.fh(v-if="showModal")
    // div.wrap-content.f.fh
      span 閲覧権限がありません。
    div.wrap-content
      div.mb24
        span.block.text-center トレーナーとしての閲覧権限がありません。
        span.block.text-center 権限をリクエストしてください。
      div.f.fc.mb36
        span.button-request.px12.pt9.pb8(
          v-if="showButton",
          @click="sendRequest"
        ) 権限をリクエスト
        span(v-else) （リクエスト送信済み）
      //- div.f.fc.mb50
        span.for-trainee(@click="$router.push('/client-sign-in')") トレーナーでない方はこちら
      div.f.fc
        span.sign-out(@click="logout") サインアウト
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.require-activation {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 101;
  background: $primary_bg_color;
  overflow: scroll;
  .wrap-content {
    width: 80%;
    margin: 0 auto;
    span {
      text-align: center;
      font-size: 14px;
      font-weight: bold;
    }
    .button-request {
      color: $secondary_active_color;
      background: $active_color;
      border-radius: 3px;
      font-weight: normal;
    }
    .sign-out,
    .for-trainee {
      display: inline-block;
      border-bottom: solid $primary_text_color 1px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import { api } from '@/components/utils/firebase'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  data () {
    return {
      user: null,
      emails: null,
      admins: null,
      showModal: false,
      showButton: true,
      traineesPath: [
        'client_inbox',
        'client_schedule',
        'client_record',
        'client_product',
        'client_account'
      ]
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  // watch: {
  //   uid: function () {

  //   }
  // },
  async mounted () {
    this.user = await this.getUserByUid(this.uid)
    this.emails = await this.getActiveEmails()
    // var traineeExists = await this.checkAuthedClientExisting
    var isTraineeView = this.traineesPath.includes(this.$route.name)
    if (this.emails.indexOf(this.user.email) === -1 && !isTraineeView) this.showModal = true
    if (this.user.email &&
      (this.user.email.indexOf('@deportareclub.com') !== -1 ||
      this.user.email.indexOf('@deportaretechnologies.com') !== -1)) this.showModal = false
  },
  methods: {
    ...mapActionsAuth([
      'getUserByUid',
      'getActiveEmails',
      // 'checkAuthedClientExisting',
      'signOut'
    ]),
    async sendRequest () {
      this.showButton = false

      var text = `
        権限のリクエストが来ました。
        お名前：${this.user.name}
        email：${this.user.email}

        こちらから${this.user.name}さんのアカウントをアクティベートできます。
        https://karte.deportareclub.tech/trainers
        `
      // const response = await fetch(`${api}/sendRequestMail`, {
      await fetch(`${api}/sendRequestMail`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text
        })
      })
      // const result = await response.json()

      alert('リクエストを出しました。承認されるまでお待ちください。')

      // mixpanel.track('onSendRequest', { createdAt: new Date() })
    },
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)

      // mixpanel.track('onLogout', { createdAt: new Date() })
    }
  }
}
</script>
