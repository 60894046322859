import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import isSunday from 'date-fns/isSunday'
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth'
import ja from 'date-fns/locale/ja'

import db, { firebase } from '@/components/utils/firebase'

const isDateEqual = (now, then) => {
  return format(now, 'yyyyMMdd', { locale: ja }) === format(then, 'yyyyMMdd', { locale: ja })
}

const createHabits = async (clientId, now) => {
  const habitsRef = db.collection('HABITS').doc(clientId)
  let habitsDoc = await habitsRef.get()
  if (!habitsDoc.exists) {
    await db.collection('HABITS').doc(clientId).set({ body: [] })
  }

  const data = {
    createdAt: now,
    daily: [],
    weekly: [],
    monthly: []
  }

  await habitsRef.update({ body: firebase.firestore.FieldValue.arrayUnion(data) })
  habitsDoc = await habitsRef.get()
  return habitsDoc
}

// exported
const destroyHabitsRecords = async (clientId, now) => {
  const today = format(now, 'yyyyMMdd', { locale: ja })
  await db.collection('HABITS').doc(clientId).collection('RECORDS').doc(today).delete()
}

// exported
const destroyHabits = async (clientId) => {
  await db.collection('HABITS').doc(clientId).delete()
}

const getAllHabits = async (clientId, now) => {
  let habitsDoc = await db.collection('HABITS').doc(clientId).get()
  habitsDoc = habitsDoc.exists ? habitsDoc : await createHabits(clientId, now)
  return habitsDoc
}

// exported
const getHabits = async (clientId, now, habitType) => {
  let habitsDoc = await db.collection('HABITS').doc(clientId).get()
  habitsDoc = habitsDoc.exists ? habitsDoc : await createHabits(clientId, now)

  const { body } = habitsDoc.data()

  const { daily, weekly, monthly } = body.filter(h => isDateEqual(h.createdAt.toDate(), now))[0]

  switch (habitType) {
    case 'DAILY':
      return daily
    case 'WEEKLY':
      return weekly
    case 'MONTHLY':
      return monthly
    default:
      return []
  }
}

// exported
const addHabit = async (clientId, now, habitType, habitName) => {
  const habitsRef = db.collection('HABITS').doc(clientId)
  let habitsDoc = await habitsRef.get()

  if (habitsDoc.exists) {
    const { body } = habitsDoc.data()
    const habits = body.filter(h => isDateEqual(h.createdAt.toDate(), now))
    if (habits.length === 0) {
      habitsDoc = await createHabits(clientId, now)
    }
  } else {
    habitsDoc = await createHabits(clientId, now)
  }

  const { body } = habitsDoc.data()
  for (const b of body) {
    if (isDateEqual(b.createdAt.toDate(), now)) {
      switch (habitType) {
        case 'DAILY':
          b.daily.push(habitName)
          break
        case 'WEEKLY':
          b.weekly.push(habitName)
          break
        case 'MONTHLY':
          b.monthly.push(habitName)
          break
      }
      break
    }
  }
  await habitsRef.set({ body }, { merge: true })
}

// exported
const deleteHabit = async (clientId, now, habitType, index) => {
  const habitsRef = db.collection('HABITS').doc(clientId)
  const habitsDoc = await habitsRef.get()

  if (!habitsDoc.exists) { return }

  const { body } = habitsDoc.data()
  for (const b of body) {
    if (isDateEqual(b.createdAt.toDate(), now)) {
      switch (habitType) {
        case 'DAILY':
          b.daily.splice(index, 1)
          break
        case 'WEEKLY':
          b.weekly.splice(index, 1)
          break
        case 'MONTHLY':
          b.monthly.splice(index, 1)
          break
      }
      break
    }
  }
  await habitsRef.set({ body }, { merge: true })
}

const createHabitsRecord = async (clientId, now) => {
  const getMatchingHabits = ({ body }) => {
    body.sort((a, b) => {
      if (isBefore(a.createdAt.toDate(), b.createdAt.toDate())) return -1
      else if (isBefore(b.createdAt.toDate(), a.createdAt.toDate())) return 1
      else return 0
    })

    let habitsFrom = null
    let daily = []
    let weekly = []
    let monthly = []
    let habitFound = false
    for (const [index, b] of body.entries()) {
      if (isBefore(now, b.createdAt.toDate())) {
        if (index === 0) {
        } else {
          habitsFrom = body[index - 1].createdAt
          daily = body[index - 1].daily
          weekly = body[index - 1].weekly
          monthly = body[index - 1].monthly
        }
        habitFound = true
        break
      }
    }
    // if (!habitFound)
    if (!habitFound || body.length === 1) {
      // if (!habitFound) {
      const last = body.length - 1
      habitsFrom = body[last].createdAt
      daily = body[last].daily
      weekly = body[last].weekly
      monthly = body[last].monthly
    }

    return { daily, weekly, monthly, habitsFrom }
  }

  const habits = (names) => {
    return names.map(n => {
      return {
        name: n,
        completed: false
      }
    })
  }

  // const updateRecordWithPreviousData = async (clientId, data) => {
  //   const { date } = data
  //   const snapshot = await db.collection('HABITS').doc(clientId).collection('RECORDS').get()
  //   if (!snapshot.empty) {
  //     const ids = snapshot.docs.map(doc => doc.id)
  //     ids.sort()
  //     const today = format(date, 'yyyyMMdd', { locale: ja })
  //     let previous = ''
  //     let previousFound = false
  //     for (const [index, id] of ids.entries()) {
  //       if (id > today) {
  //         if (index === 0) {
  //         } else {
  //           previous = ids[index - 1]
  //         }
  //         previousFound = true
  //       }
  //     }
  //     // if (!previousFound)
  //     if (!previousFound || ids.length === 1) {
  //       previous = ids[ids.length - 1]
  //     }
  //     const previousDoc = await db.collection('HABITS').doc(clientId).collection('RECORDS').doc(previous).get()
  //     if (isDateEqual(previousDoc.data().habitsFrom.toDate(), data.habitsFrom.toDate())) { // same habits
  //       if (!isSunday(date)) {
  //         data = {
  //           ...data,
  //           weekly: previousDoc.data().weekly
  //         }
  //       }
  //       if (!isFirstDayOfMonth(date)) {
  //         data = {
  //           ...data,
  //           monthly: previousDoc.data().monthly
  //         }
  //       }
  //     }
  //   }
  //   return data
  // }

  const today = format(now, 'yyyyMMdd', { locale: ja })
  const habitDoc = await getAllHabits(clientId, now)
  const { daily, weekly, monthly, habitsFrom } = getMatchingHabits(habitDoc.data())

  let data = {
    habitsFrom,
    date: now,
    daily: habits(daily),
    weekly: habits(weekly),
    monthly: habits(monthly)
  }

  // data = await updateRecordWithPreviousData(clientId, data)

  await db.collection('HABITS').doc(clientId).collection('RECORDS').doc(today).set(data)
  const recordDoc = await db.collection('HABITS').doc(clientId).collection('RECORDS').doc(today).get()
  return recordDoc
}

// exported
const getHabitsRecord = async (clientId, now) => {
  const today = format(now, 'yyyyMMdd', { locale: ja })
  let recordDoc = await db.collection('HABITS').doc(clientId).collection('RECORDS').doc(today).get()

  // var isNew = (recordDoc.exists) ? false : true
  recordDoc = recordDoc.exists ? recordDoc : await createHabitsRecord(clientId, now)
  var todayRecord = recordDoc.data()

  var needUpdate = false

  // if (isNew) {
  const getMatchingHabits = ({ body }) => {
    body.sort((a, b) => {
      if (isBefore(a.createdAt.toDate(), b.createdAt.toDate())) return -1
      else if (isBefore(b.createdAt.toDate(), a.createdAt.toDate())) return 1
      else return 0
    })

    let habitsFrom = null
    let daily = []
    let weekly = []
    let monthly = []
    let habitFound = false
    for (const [index, b] of body.entries()) {
      if (isBefore(now, b.createdAt.toDate())) {
        if (index === 0) {
        } else {
          habitsFrom = body[index - 1].createdAt
          daily = body[index - 1].daily
          weekly = body[index - 1].weekly
          monthly = body[index - 1].monthly
        }
        habitFound = true
        break
      }
    }
    // if (!habitFound)
    // if (!habitFound || body.length === 1) {
    if (!habitFound) {
      const last = body.length - 1
      habitsFrom = body[last].createdAt
      daily = body[last].daily
      weekly = body[last].weekly
      monthly = body[last].monthly
    }

    return { daily, weekly, monthly, habitsFrom }
  } // getMatchingHabits

  const habitDoc = await getAllHabits(clientId, now)

  // 直近過去のhabit
  const { daily, weekly, monthly, habitsFrom } = getMatchingHabits(habitDoc.data())

  var habitExists = false

  // habitにあってrecordにないものを追加
  for (var i = 0; i < daily.length; i++) {
    for (var j = 0; j < todayRecord.daily.length; j++) {
      if (daily[i] === todayRecord.daily[j].name) habitExists = true
    }
    if (!habitExists) {
      todayRecord.daily.push({ name: daily[i], completed: false })
      needUpdate = true
    }
    habitExists = false
  }

  for (var i = 0; i < weekly.length; i++) {
    for (var j = 0; j < todayRecord.weekly.length; j++) {
      if (weekly[i] === todayRecord.weekly[j].name) habitExists = true
    }
    if (!habitExists) {
      todayRecord.weekly.push({ name: weekly[i], completed: false })
      needUpdate = true
    }
    habitExists = false
  }

  for (var i = 0; i < monthly.length; i++) {
    for (var j = 0; j < todayRecord.monthly.length; j++) {
      if (monthly[i] === todayRecord.monthly[j].name) habitExists = true
    }
    if (!habitExists) {
      todayRecord.monthly.push({ name: monthly[i], completed: false })
      needUpdate = true
    }
    habitExists = false
  }

  // recordにあってhabitにないものを削除
  todayRecord.daily = todayRecord.daily.filter(e => {
    if (!daily.includes(e.name)) needUpdate = true
    return daily.includes(e.name)
  })
  todayRecord.weekly = todayRecord.weekly.filter(e => {
    if (!weekly.includes(e.name)) needUpdate = true
    return weekly.includes(e.name)
  })
  todayRecord.monthly = todayRecord.monthly.filter(e => {
    if (!monthly.includes(e.name)) needUpdate = true
    return monthly.includes(e.name)
  })

  // } // if (isNew)

  // weekly, monthlyのcompletedを反映させる
  // weekly
  var curr = new Date(now) // get current date
  var first = curr.getDate() - curr.getDay() // Gets day of the month (e.g. 21) - the day of the week (e.g. wednesday = 3) = Sunday (18th) - 6
  var last = first + 6 // last day is the first day + 6
  var lastSun = new Date(curr.setDate(first))
  var nextSat = new Date(curr.setDate(last))
  // 先週の日曜から〜今週の土曜までのRECORDを取得
  var recordsInWeek = await db.collection('HABITS')
    .doc(clientId).collection('RECORDS')
    .where('date', '>=', lastSun)
    .where('date', '<=', nextSat)
    .get()
    .then(q => {
      return q.docs.map(d => {
        var record = d.data()
        record.id = d.id
        return record
      })
    })
  var weeklyTodosInWeek = recordsInWeek.map(e => { return e.weekly })
  weeklyTodosInWeek = weeklyTodosInWeek.reduce((pre, current) => { pre.push(...current); return pre }, [])
  var completedWeeklyTodos = weeklyTodosInWeek.filter(e => { return e.completed })
  completedWeeklyTodos = completedWeeklyTodos.map(e => { return e.name })
  todayRecord.weekly = todayRecord.weekly.map(e => {
    if (completedWeeklyTodos.includes(e.name)) {
      e.completed = true
      needUpdate = true
    }
    return e
  })

  // monthly
  var startMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  var endMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
  // 今月のRECORDを取得
  var recordsInMonth = await db.collection('HABITS')
    .doc(clientId).collection('RECORDS')
    .where('date', '>=', startMonth)
    .where('date', '<=', endMonth)
    .get()
    .then(q => {
      return q.docs.map(d => {
        var record = d.data()
        record.id = d.id
        return record
      })
    })
  var monthlyTodosInWeek = recordsInMonth.map(e => { return e.monthly })
  monthlyTodosInWeek = monthlyTodosInWeek.reduce((pre, current) => { pre.push(...current); return pre }, [])
  var completedMonthlyTodos = monthlyTodosInWeek.filter(e => { return e.completed })
  completedMonthlyTodos = completedMonthlyTodos.map(e => { return e.name })
  todayRecord.monthly = todayRecord.monthly.map(e => {
    if (completedMonthlyTodos.includes(e.name)) {
      e.completed = true
      needUpdate = true
    }
    return e
  })

  if (needUpdate) await db.collection('HABITS').doc(clientId).collection('RECORDS').doc(today).set(todayRecord)

  return todayRecord
  // return (todayRecord) ? todayRecord : recordDoc.data()
}

const getAllHabitsRecords = async (clientId) => {
  const habitsRecords = await db.collection('HABITS')
    .doc(clientId)
    .collection('RECORDS')
    .get()
    .then((q) => {
      return q.docs.map((d) => {
        var record = d.data()
        record.id = d.id
        return record
      })
    })
  return habitsRecords
}

// exported
const completeHabit = async (clientId, now, habitType, index, completed) => {
  const today = format(now, 'yyyyMMdd', { locale: ja })
  const recordRef = db.collection('HABITS').doc(clientId).collection('RECORDS').doc(today)
  const recordDoc = await recordRef.get()

  const { daily, weekly, monthly } = recordDoc.data()
  switch (habitType) {
    case 'DAILY':
      daily[index].completed = completed
      await recordRef.set({ daily }, { merge: true })
      break
    case 'WEEKLY':
      weekly[index].completed = completed
      await recordRef.set({ weekly }, { merge: true })
      break
    case 'MONTHLY':
      monthly[index].completed = completed
      await recordRef.set({ monthly }, { merge: true })
      break
  }

  const updated = await recordRef.get()
  return updated.data()
}

export {
  getHabits,
  addHabit,
  deleteHabit,
  destroyHabits,
  getHabitsRecord,
  getAllHabitsRecords,
  destroyHabitsRecords,
  completeHabit
}
