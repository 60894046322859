import db from '@/components/utils/firebase'
import { COLLECTIONS } from '@/enums'

export const state = () => ({
  clients: null
})

export const mutations = {
  replaceClients (state, value) {
    state.clients = value
  },
  unshiftClient (state, client) {
    state.clients.unshift(client)
  },
  deleteClient (state, clientId) {
    state.clients = state.clients.filter(e => { return (e.uid !== clientId) })
  }
}

export const actions = {
  async loadClients ({ commit }) {
    return new Promise(async resolve => {
      var clients = await db.collection(COLLECTIONS.CLIENTS)
        .orderBy('updated', 'desc')
        .get()
        .then(q => {
          return q.docs.map(d => {
            var client = d.data()
            client.id = d.id
            return client
          })
        })
      commit('replaceClients', clients)
      resolve(clients)
    })
  },
  async getClient ({ commit }, clientId) {
    return new Promise(async resolve => {
      var client = await db.collection(COLLECTIONS.CLIENTS)
        .doc(clientId)
        .get()
        .then(d => {
          var client = d.data()
          client.id = d.id
          return client
        })
      resolve(client)
    })
  },
  async setClient ({ commit }, clientObj) {
    return new Promise(async resolve => {
      await db.collection(COLLECTIONS.CLIENTS).doc(clientObj.uid).set(clientObj, { merge: true })
      db.collection(COLLECTIONS.PAIRS).add({
        clientId: clientObj.uid,
        trainerId: clientObj.author,
        author: clientObj.author
      })
      commit('unshiftClient', clientObj)
      resolve(clientObj)
    })
  },
  async updateClient ({ commit }, clientObj) {
    return new Promise(async resolve => {
      await db.collection(COLLECTIONS.CLIENTS).doc(clientObj.uid).set(clientObj, { merge: true })
      resolve(clientObj)
    })
  },
  deleteClient ({ commit }, clientId) {
    commit('deleteClient', clientId)
    db.collection(COLLECTIONS.CLIENTS).doc(clientId).delete()
  }
}
